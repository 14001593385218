import React from "react"
import Styles from "./profileservice.module.css"
const ProfileService: React.FC = () => {
  return (
    <>
      <div
        className={`row ${Styles.services} mx-0 justify-content-center`}
      >
        <div className="col-lg-3 my-lg-0 my-4">
          <div className={`${Styles.servicItemTop}`}>
            <img src="/Assets/Images/Icons/head-mail-icon.svg" />
          </div>
          <div className={Styles.servicItemBottom}>
            پیغام | Message
          </div>
        </div>

        <div className="col-lg-3 my-lg-0 my-4">
          <div className={`${Styles.servicItemTop}`}>
            <img src="/Assets/Images/Icons/head-lamp-icon.svg" />
          </div>
          <div className={Styles.servicItemBottom}>
            مشتاق | Keen
          </div>
        </div>

        <div className="col-lg-3 my-lg-0 my-4">
          <div className={`${Styles.servicItemTop}`}>
            <img src="/Assets/Images/Icons/head-share-icon.svg" />
          </div>
          {/* <div className={Styles.servicItemBottom}> {followed}به‌ اشتراک‌گذاری   |   Share</div> */}
        </div>
      </div>
    </>
  )
}

export { ProfileService }
