import React from "react"
import { Level } from "../common/Levels/Level"

import { H1 } from "../common/Typography/H1"
import { H2 } from "../common/Typography/H2"
import { H3 } from "../common/Typography/H3"
import { H4 } from "../common/Typography/H4"
import { H5 } from "../common/Typography/H5"
import { H6 } from "../common/Typography/H6"
import { P } from "../common/Typography/P"
import { Span } from "../common/Typography/Span"

const DesignSystem: React.FC = () => {
  return (
    <>
      <div className="container">
        <H1 text="Typography" className="py-4" />
        <H2 text="Headings" className="py-3 text-success" />
        <H1
          text="H1  Design system hamnavazi"
          className="py-2"
        />
        <H2
          text="H2  Design system hamnavazi"
          className="py-2"
        />
        <H3
          text="H3  Design system hamnavazi"
          className="py-2"
        />
        <H4
          text="H4  Design system hamnavazi"
          className="py-2"
        />
        <H5
          text="H5  Design system hamnavazi"
          className="py-2"
        />
        <H6
          text="H6  Design system hamnavazi"
          className="py-2"
        />
        <Span
          text="Span  Design system hamnavazi"
          className="py-2"
        />
        <P text="P  Design system hamnavazi" className="py-2" />

        <div className="d-flex justify-content-center align-items-center my-5">
          <div className="mx-3">
            <Level instrumentScore={1} />
          </div>
          <div className="mx-3">
            <Level instrumentScore={2} />
          </div>
          <div className="mx-3">
            <Level instrumentScore={3} />
          </div>
          <div className="mx-3">
            <Level instrumentScore={4} />
          </div>
        </div>
      </div>
    </>
  )
}

export { DesignSystem }
