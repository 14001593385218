import React from "react"
import { DesignSystem } from "../components/DesignSystem/DesignSystem"
import { Switch, Route, Redirect } from "react-router-dom"

import { SearchScreen } from "../screens/Search/SearchScreen"
import { Profile } from "../screens/Profile"

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/Profile" component={Profile} />
        <Route exact path="/" component={DesignSystem} />
      </Switch>
    </div>
  )
}

export default App
